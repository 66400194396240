@import '../../../assets/less/theme.less';

.nomatch {
  background: @main-background-color;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 100;
  left: 100;
  right: 0;
  bottom: 0;
  z-index: 100;
}
